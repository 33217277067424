<template>
    <!-- eslint-disable -->
    <Form :action="action" :labelVal="label" :layananVal="layanan" :id="id" :dataLayananVal="dataLayanan" :isShowJenisPemeriksaanVal="isShowJenisPemeriksaan" :jenisPemeriksaanVal="jenisPemeriksaan" :dataJenisPemeriksaanVal="dataJenisPemeriksaan" :detailSediaanVal="detailSediaan" />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var label = ref('')
        var layanan = ref(null)
        var dataLayanan = ref([])
        var jenisPemeriksaan = ref(null)
        var detailSediaan = ref([])
        var dataJenisPemeriksaan = ref([])
        var isShowJenisPemeriksaan = ref(false)

        const getData = () => {
            $http({
                url: '/pemeriksaan/sediaan/ubah/'+props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    const {labelVal, jenisPemeriksaanVal, layananVal, dataSediaan, detailSediaanVal} = res.data.data
                    label.value = labelVal
                    layanan.value = layananVal
                    jenisPemeriksaan.value = jenisPemeriksaanVal
                    dataLayanan.value = dataSediaan.dataLayananVal
                    dataJenisPemeriksaan.value = dataSediaan.dataJenisPemeriksaanVal
                    detailSediaan.value = detailSediaanVal

                    if(layananVal)
                        isShowJenisPemeriksaan.value = true
                })
                .catch(err => {
                    console.log(err);
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()

        return {
            label, layanan, dataLayanan, jenisPemeriksaan, dataJenisPemeriksaan, detailSediaan,
            isShowJenisPemeriksaan
        }
    },
    data(){
        return{
            action: 'Ubah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>